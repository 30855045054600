<template>
  <div style="width: 100%">
    <b-table
      show-empty
      v-show="table.items.length > 0"
      class="position-relative"
      :per-page="table.perPage"
      :items="table.items"
      :fields="table.fields"
      :current-page="table.currentPage"
      :sort-by.sync="table.sortByPropostas"
      :sort-desc.sync="table.sortDescPropostas"
      :sort-direction="table.sortDirectionPropostas"
      :busy="table.isBusy"
    >
      <template v-slot:table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle mr-1"></b-spinner>
          <strong>Carregando...</strong>
        </div>
      </template>
      <template #cell(matricula)="row">
        {{ row.item.segurado ? row.item.segurado.matricula : '' }}
      </template>
      <template #cell(nome)="row">
        {{ row.item.segurado ? (row.item.segurado.tomador ? row.item.segurado.tomador.nomeCompleto : '') : '' }}
      </template>
      <template #cell(statusProposta)="row">
        <b-badge pill :variant="status[0][row.item.statusProposta]">
          {{ row.item.statusProposta == 'ContratoGerado' ? 'Contrato Gerado' : row.item.statusProposta }}
        </b-badge>
      </template>
    </b-table>
    <div v-if="table.items.length === 0" class="text-center">
      <h4>Nenhum registro encontrado.</h4>
    </div>
  </div>
</template>

<script>
  import useJwt from '@/auth/jwt/useJwt'
  import { formatarValor, formatarData } from '@/libs/utils'

  export default {
    props: {
      seguradoId: {
        type: Number,
        required: true,
      },
    },
    data() {
      return {
        formatarValor,
        formatarData,
        table: {
          currentPage: 0,
          perPage: 10,
          items: [],
          fields: [
            { key: 'codigo', label: 'Código', sortable: true },
            { key: 'nome', label: 'Nome' },
            { key: 'matricula', label: 'Matrícula' },
            {
              key: 'valor',
              label: 'Valor da Proposta',
              sortable: true,
              formatter: (value) => {
                return value.includes('R$') ? value : formatarValor(value)
              },
            },
            {
              key: 'emissao',
              label: 'Data da Proposta',
              formatter: (value, key, item) => {
                return formatarData(value)
              },
              sortable: true,
            },
            { key: 'statusProposta', label: 'Status', sortable: true },
          ],
          isBusy: undefined,
          sortDesc: false,
          sortDescPropostas: false,
          sortDirection: 'asc',
          sortDirectionPropostas: 'asc',
        },
        status: [
          {
            Aprovado: 'success',
            Pendente: 'warning',
            Negado: 'danger',
            Expirado: 'secondary',
            ContratoGerado: 'info',
          },
        ],
      }
    },
    async mounted() {
      const response = await useJwt.pesquisar('financeiro/pesquisarContratoGerado', {
        seguradoId: this.seguradoId,
      })

      this.table.items = response.data
    },
  }
</script>

<style lang="scss" scoped></style>
