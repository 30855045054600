<template>
  <b-card>
    <b-card-text>
      <h5 class="mb-2" style="text-align: center">Oops...</h5>
      <h5 class="mb-2" style="text-align: center">Este dado não foi encontrado, ou não existe</h5>
    </b-card-text>
  </b-card>
</template>

<script>
  import { BLink, BImg, BButton, BCard, BCardText } from 'bootstrap-vue'

  export default {
    components: {
      BLink,
      BImg,
      BButton,
      BCard,
      BCardText,
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/pages/page-misc.scss';
</style>
