<template>
  <v-select-pt
    name="dadoBancarioBanco"
    :value="modelValue"
    @input="
      (value) => {
        $emit('update:modelValue', value)
      }
    "
    :options="banco"
    :reduce="(option) => option.id"
    label="nome"
  ></v-select-pt>
</template>

<script>
  import BCardCode from '@core/components/b-card-code/BCardCode.vue'
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { required, dataFutura, dataPassada } from '@validations'
  import Ripple from 'vue-ripple-directive'
  import bloqueioCaractereMask from '@/@core/directives/bloqueioCaractereMask'
  import useJwt from '@/auth/jwt/useJwt'
  import Cleave from 'vue-cleave-component'
  import 'cleave.js/dist/addons/cleave-phone.br'
  import {
    Ufs,
    isLetter,
    formatarData,
    formatarDataGlobal,
    isCPFValid,
    isNumber,
    toNumeric,
    validarData,
    formatarValor,
    formatarParaNumber,
    validaCNPJ,
    validaRG,
    msgError,
  } from '@/libs/utils'
  import { valor } from '@/@core/utils/validations/validations'
  import { VueSelect } from 'vue-select'

  export default {
    components: {
      BCardCode,
      ValidationProvider,
      ValidationObserver,
      formatarData,
      formatarDataGlobal,
      isNumber,
      toNumeric,
      validarData,
      Cleave,
      formatarValor,
      VueSelect,
      dataPassada,
      dataFutura,
      validaRG,
      validaCNPJ,
    },
    directives: {
      Ripple,
    },
    data() {
      return {
        banco: [],
      }
    },
    props: {
      modelValue: {
        type: [String, Number],
        default: '',
      },
    },
    mounted() {
      this.carregarBancos()
    },
    methods: {
      carregarBancos() {
        useJwt
          .get('utils/bancos')
          .then((response) => {
            this.banco = response.data
            this.banco.forEach((banco) => {
              if (banco.codigo) banco.nome = String(banco.codigo) + ' - ' + String(banco.nome)
            })
          })
          .catch((error) => {
            console.error(error)
          })
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';

  .camera-canvas {
    text-align: center;
  }

  #modal-foto___BV_modal_footer_ {
    margin-left: auto;
    left: 0px;
    right: 0px;
    margin-right: auto;
  }

  label[for='btnCreateEditSegurado'],
  label[for='btnEraseFormSegurado'] {
    height: 17.39px;
  }

  [dir] body.dark-layout #portadorDoencaIncapacitante > label.btn.btn-outline-primary.active > span,
  [dir] body.dark-layout #portadorMolestiaGrave > label.btn.btn-outline-primary.active > span,
  [dir] body.dark-layout #falecido > label.btn.btn-outline-primary.active > span,
  [dir] body.dark-layout #exonerado > label.btn.btn-outline-primary.active > span {
    color: #ecf0f1;
  }

  .breakWord {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
  }

  .flexRowReverse {
    align-items: center;
    display: flex;
    flex-direction: row-reverse;
  }
</style>
