import { formatarData } from '../Formatacao/Formatacao'
/**
 * Verifica se um caractere digitado  uma letra.
 *
 * @param {KeyboardEvent} e - Evento do teclado.
 * @returns {boolean} Verdadeiro se  uma letra, falso caso contr  rio.
 */
export function isLetter(e: KeyboardEvent): boolean {
  const char = String.fromCharCode(e.keyCode)
  const regex = /^[a-zA-Z\u00C0-\u017F\s]+$/

  if (regex.test(char)) return true
  else if (!regex.test(char)) {
    e.preventDefault()
    return false
  }
}
/**
 * Verifica se um valor  um numero.
 *
 * @param {string | number} numero - Valor a ser verificado.
 * @returns {boolean} Verdadeiro se  um numero, falso caso contrario.
 */
export function isNumber(numero: string | number): boolean {
  if (typeof numero === 'number' && isFinite(numero)) return true
  if (typeof numero === 'string') {
    return /^[0-9]+$/.test(numero)
  }
  return false
}
/**
 * Verifica se um CPF  valido.
 *
 * @param {string} cpf - N mero de CPF a ser verificado.
 * @returns {boolean} Verdadeiro se o CPF  v lido, falso caso contr rio.
 */
export function isCPFValid(cpf: string): boolean {
  if (cpf == undefined) return false

  cpf = cpf.replace(/[^\d]+/g, '')
  if (cpf == '' || cpf.length !== 11) return false

  // Elimina CPFs invalidos conhecidos
  const invalidCPFs = [
    '00000000000',
    '11111111111',
    '22222222222',
    '33333333333',
    '44444444444',
    '55555555555',
    '66666666666',
    '77777777777',
    '88888888888',
    '99999999999',
  ]

  if (invalidCPFs.includes(cpf)) return false

  // Valida 1o digito
  let add = 0
  for (let i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i)
  let rev = 11 - (add % 11)
  if (rev == 10 || rev == 11) rev = 0
  if (rev != parseInt(cpf.charAt(9))) return false

  // Valida 2o digito
  add = 0
  for (let i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i)
  let rev2 = 11 - (add % 11)
  if (rev2 == 10 || rev2 == 11) rev2 = 0
  if (rev2 != parseInt(cpf.charAt(10))) return false

  return true
}
/**
 * Valida um número de CNPJ.
 *
 * @param {string} cnpj - O CNPJ a ser validado.
 * @returns {boolean} - Retorna verdadeiro se o CNPJ for válido, caso contrário, falso.
 */
export function validaCNPJ(cnpj: string): boolean {
  cnpj = cnpj.replace(/[^\d]+/g, '') // Remove caracteres não numéricos

  if (cnpj.length !== 14) {
    return false
  }

  // Elimina CNPJs inválidos conhecidos
  if (/^(\d)\1+$/.test(cnpj)) {
    return false
  }

  // Valida DVs
  let tamanho = cnpj.length - 2
  let numeros = cnpj.substring(0, tamanho)
  let digitos = cnpj.substring(tamanho)
  let soma = 0
  let pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }

  let resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  if (resultado !== parseInt(digitos.charAt(0))) {
    return false
  }

  tamanho += 1
  numeros = cnpj.substring(0, tamanho)
  soma = 0
  pos = tamanho - 7

  for (let i = tamanho; i >= 1; i--) {
    soma += parseInt(numeros.charAt(tamanho - i)) * pos--
    if (pos < 2) pos = 9
  }

  resultado = soma % 11 < 2 ? 0 : 11 - (soma % 11)
  return resultado === parseInt(digitos.charAt(1))
}

/**
 * Verifica se um RG (Registro Geral) é válido
 *
 * @param {string} rg - O RG a ser validado
 * @returns {boolean} - Retorna verdadeiro se o RG for válido, caso contrário, falso
 */
export function validaRG(rg: string): boolean {
  if (!rg) return false
  rg = rg.replace(/[^\d]+/g, '')

  if (rg === '' || rg.length < 8 || rg.length > 9) {
    return false // RG inválido, pois o tamanho não é válido
  }

  // Elimina RGs inválidos conhecidos
  if (/^(0{8,9}|1{8,9}|2{8,9}|3{8,9}|4{8,9}|5{8,9}|6{8,9}|7{8,9}|8{8,9}|9{8,9})$/.test(rg)) {
    return false // RG inválido, pois é um padrão de RG conhecido que não é válido
  }

  return true
}

/**
 * Verifica se uma data no formato dd/mm/yyyy é válida
 *
 * @param {string} data - A data a ser validada
 * @returns {boolean} - Retorna verdadeiro se a data for válida, caso contrário, falso
 */
export function validarData(data: string): boolean {
  if (!data) return false

  // Regex para verificar o formato dd/mm/yyyy
  const regexData: RegExp = /^\d{2}\/\d{2}\/\d{4}$/
  if (!regexData.test(data)) {
    return false
  }

  // Desestrutura a data em dia, mês e ano
  const [dia, mes, ano]: number[] = data.split('/').map(Number)

  // Cria um objeto de data
  const dataObj: Date = new Date(ano, mes - 1, dia)

  // Verifica se a data no objeto corresponde à data fornecida
  return dataObj.getDate() === dia && dataObj.getMonth() === mes - 1 && dataObj.getFullYear() === ano
}
/**
 * Verifica se o objeto é nulo ou indefinido e se não tem propriedades próprias enumeráveis.
 *
 * @param {any} objeto - O objeto a ser verificado
 * @returns {boolean} - Retorna true se o objeto for nulo, indefinido ou não tiver propriedades próprias enumeráveis, caso contrário, retorna false
 */
export function isNullObject(objeto: any): boolean {
  if (objeto === null || objeto === undefined) {
    return true
  }

  for (const chave in objeto) {
    if (Object.prototype.hasOwnProperty.call(objeto, chave)) {
      return false
    }
  }

  return true
}
