<template>
  <!-- RELATÓRIO -->
  <div>
    <vue-html2pdf
      :show-layout="false"
      :float-layout="true"
      :enable-download="false"
      :preview-modal="false"
      :paginate-elements-by-height="800"
      filename="ConsigPrev - Relatório"
      :pdf-quality="2"
      :manual-pagination="true"
      pdf-format="a4"
      pdf-orientation="portrait"
      pdf-content-width="780px"
      @beforeDownload="componentesRenderizados && beforeDownload($event)"
      foot-row-class="bold-footer-row"
      ref="relatorioPropostaSimples"
    >
      <section slot="pdf-content" style="background-color: white">
        <b-card class="fundoRelatorio cabecalhoTable">
          <div class="fundoRelatorio titulo">
            <div class="d-none d-lg-flex align-items-center fundoRelatorio">
              <img :src="logoAgenda" />

              <h2 class="brand-text text-primary ml-1" style="margin-bottom: 0px; margin-left: 0px !important">ConsigPrev</h2>
            </div>
            <div class="lineBlue"></div>
            <div class="lineGray"></div>
            <h1 class="fundoRelatorio instituto" style="margin-bottom: 0px; margin-top: 5px">
              {{ userData.Instituto.nome }}
            </h1>
          </div>

          <!-- Relatório Simulação -->
          <div style="text-align: center; font-weight: bold; font-size: 20px; margin-bottom: 8px">Simulação</div>
          <b-row>
            <b-col cols="21" xl="6" class="d-flex justify-content-between flex-column">
              <div class="d-flex justify-content-start">
                <div class="d-flex flex-column ml-1">
                  <div class="mb-1">
                    <h4 class="mb-0">{{ parametro.segurado.tomador.nomeCompleto }}</h4>
                    <span class="card-text">CPF: {{ parametro.segurado.tomador.cpf }}</span
                    ><br />
                    <span class="card-text"
                      >{{ parametro.origem }}:
                      <span class="badge badge-light-primary badge-pill">{{
                        this.formatarCodigoSequencial(parametro.codigo)
                      }}</span
                      ><span v-if="parametro.compraDeDivida"> - <b>Compra de dívida</b></span></span
                    ><br />

                    <span class="card-text"
                      >Data da Proposta:
                      <span class="badge badge-light-primary badge-pill"> {{ formatarData(parametro.createdAt) }} </span> </span
                    ><br />
                    <span class="card-text-sm"> <b>Proposta válida para esta data, sujeito à altereção</b> </span>
                  </div>
                </div>
              </div>
            </b-col>

            <b-col cols="12" xl="6">
              <table class="mt-2 mt-xl-0 w-100">
                <tr>
                  <th class="pb-50">
                    <feather-icon icon="BriefcaseIcon" class="mr-75" />
                    <span class="font-weight-bold">Órgão</span>
                  </th>
                  <td class="pb-50">
                    {{ parametro.segurado.orgao.nome }}
                  </td>
                </tr>
                <tr>
                  <th style="width: 97px" class="pb-50">
                    <feather-icon icon="StarIcon" class="mr-75" />
                    <span class="font-weight-bold">Categoria</span>
                  </th>
                  <td class="pb-50 text-capitalize">
                    {{ parametro.segurado.situacaoPrevidenciaria }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon icon="PhoneIcon" class="mr-75" />
                    <span class="font-weight-bold">Telefone</span>
                  </th>
                  <td>
                    {{ parametro.segurado.tomador.telefone }}
                  </td>
                </tr>
                <tr>
                  <th>
                    <feather-icon icon="AtSignIcon" class="mr-75" />
                    <span class="font-weight-bold">E-mail</span>
                  </th>
                  <td>
                    {{ parametro.segurado.tomador.email }}
                  </td>
                </tr>
              </table>
            </b-col>
          </b-row>
          <b-row class="mt-2">
            <b-col md="4">
              <b-form-group :label="'Valor da ' + parametro.origem" label-for="valor">
                <b-form-input
                  id="valor"
                  placeholder="Valor da Proposta"
                  v-model="parametro.valorBaseCalculo"
                  :readonly="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Parcelas" label-for="parcelas">
                <b-form-input id="parcelas" placeholder="Parcelas" v-model="parametro.parcelas" :readonly="true" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="1º Parcela" label-for="primeiraParcela">
                <b-form-input
                  id="primeiraParcela"
                  class="form-control"
                  type="text"
                  placeholder="DD/MM/AAAA"
                  v-model="parametro.primeiraParcela"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row>
            <b-col md="4">
              <b-form-group label="Valor 1º Parcela" label-for="valorPrimeiraParcela">
                <b-form-input
                  id="valorPrimeiraParcela"
                  placeholder="Valor 1º Parcela"
                  v-model="parametro.valorPrimeiraParcela"
                  :readonly="true"
                />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Taxa %" label-for="taxa">
                <b-form-input id="taxa" placeholder="Taxa %" v-model="parametro.taxa" :readonly="true" />
              </b-form-group>
            </b-col>
            <b-col md="4">
              <b-form-group label="Valor Final" label-for="valorFinal">
                <b-form-input id="valorFinal" v-model="valorFinal" :readonly="true" />
              </b-form-group>
            </b-col>
          </b-row>
          <b-row v-if="parametro.compraDeDivida">
            <b-col md="3">
              <b-form-group label="Valor da Dívida" label-for="valorDaDivida">
                <b-form-input
                  id="valorDaDivida"
                  placeholder="Valor da Dívida"
                  v-model="parametro.valorDaDivida"
                  :readonly="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="3">
              <b-form-group label="Valor Remanescente" label-for="valorRemanescente">
                <b-form-input
                  id="valorRemanescente"
                  placeholder="Valor Remanescente"
                  v-model="parametro.valorRemanescente"
                  :readonly="true"
                ></b-form-input>
              </b-form-group>
            </b-col>
            <b-col md="6">
              <b-form-group label="Banco" label-for="banco">
                <b-form-input id="banco" placeholder="Banco" v-model="parametro.nomeBanco" :readonly="true"></b-form-input>
              </b-form-group>
            </b-col>
          </b-row>
          <div v-for="(linhaDaTabela, indice) in linhasTabela" :key="`tabelaItem_${indice}`">
            <b-form>
              <b-row>
                <b-col md="12">
                  <b-table
                    :key="linhasTabela.length"
                    striped
                    responsive
                    class="position-relative"
                    :items="linhaDaTabela"
                    :fields="fieldsVisualizarParcelas"
                  >
                    <template #cell(valor)="row">
                      {{ formatarValor(row.item.valor) }}
                    </template>
                    <template #cell(vencimento)="row">
                      {{ formatarData(row.item.vencimento) }}
                    </template>
                  </b-table>
                </b-col>
              </b-row>
            </b-form>
            <div v-if="linhasTabela.length - 1 != indice" class="html2pdf__page-break" />
          </div>
        </b-card>
      </section>
    </vue-html2pdf>
  </div>
</template>

<script>
  import { ValidationProvider, ValidationObserver } from 'vee-validate'
  import { isNumber, formatarValor, formatarData, formatarCodigoSequencial } from '@/libs/utils'
  import useJwt from '@/auth/jwt/useJwt'
  import VueHtml2pdf from 'vue-html2pdf'

  export default {
    components: {
      ValidationProvider,
      ValidationObserver,
      VueHtml2pdf,
    },
    props: {
      parametro: {},
    },
    data() {
      return {
        aImg: require('@/assets/images/logo/Alogo2.png'),
        userData: this.$jwtDecode(localStorage.getItem('userData')).userData,
        isNumber,
        formatarValor,
        formatarData,
        formatarCodigoSequencial,
        formatarValor,
        number: {},
        sortByVisualizarParcelas: '',
        sortDescVisualizarParcelas: false,
        showRelatorio: false,
        sortDirectionVisualizarParcelas: 'asc',
        perPageVisualizarParcelas: 6,
        currentPageVisualizarParcelas: 1,
        fieldsVisualizarParcelas: [
          { key: 'parcela', sortable: true },
          { key: 'valor', sortable: true },
          { key: 'vencimento', sortable: true },
        ],
        linhasTabela: [],
        taxaShow: {},
        relatorioDeCompetencia: null,
        itemsRelatorio: {},
        passouprimeiraVez: false,
        componentesRenderizados: false,
        valorFinal: null,
      }
    },
    async mounted() {
      await this.carregarParcelas()
      this.taxaShow = parseFloat(this.parametro.taxa.replace(/,/g, '')) / 100
      this.componentesRenderizados = true
      this.$emit('mounted')
    },
    computed: {
      logoAgenda() {
        return this.userData.Instituto?.logoRelatorio?.logo ?? this.aImg
      },
    },
    methods: {
      async carregarParcelas() {
        await useJwt
          .get('proposta/carregarParcelas/' + this.parametro.id)
          .then((response) => {
            let dados = response.data
            let valores = []
            dados.forEach((dados) => {
              const valorNumerico = parseFloat(dados.valor.replace(',', '.'))
              valores.push(valorNumerico)
            })

            const somaValores = valores.reduce((acumulador, valor) => acumulador + valor, 0)

            this.valorFinal = formatarValor(somaValores)
            this.linhaDaTabela = dados
            this.linhasTabela = this.linhaDaTabela
            const primeiraParcela = this.parametro.valorPrimeiraParcela
            this.parametro.valorPrimeiraParcela = primeiraParcela.includes('R$')
              ? primeiraParcela
              : formatarValor(this.parametro.valorPrimeiraParcela)
            this.dividirLista(this.linhasTabela, 27)
          })
          .catch((error) => {
            console.error(error)
          })
      },
      async exportPDF() {
        await this.carregarParcelas()
        this.$nextTick(() => {
          this.$refs.relatorioPropostaSimples.generatePdf()
        })
      },
      async dividirLista(listaOriginal, tamanhoMaximo) {
        let primeiraPagina = listaOriginal.slice(0, 12)
        const restante = listaOriginal.slice(12)
        const sublistas = [primeiraPagina]
        let sublistaAtual = []

        restante.forEach((item, indice) => {
          if (sublistaAtual.length < tamanhoMaximo) {
            sublistaAtual.push(item)
          } else {
            sublistas.push(sublistaAtual)
            sublistaAtual = [item]
          }

          if (indice === restante.length - 1) {
            sublistas.push(sublistaAtual)
          }
        })

        this.linhasTabela = sublistas
      },
      async beforeDownload({ html2pdf, options, pdfContent }) {
        await html2pdf()
          .set(options)
          .from(pdfContent)
          .toPdf()
          .get('pdf')
          .then((pdf) => {
            const totalPages = pdf.internal.getNumberOfPages()

            for (let pagina = 1; pagina <= totalPages; pagina++) {
              pdf.setPage(pagina)
              pdf.setFontSize(10)
              pdf.setTextColor(150)

              pdf.text(
                'Pagina ' + pagina + ' de ' + totalPages,
                pdf.internal.pageSize.getWidth() * 0.88,
                pdf.internal.pageSize.getHeight() - 0.15,
              )

              pdf.setFontSize(7)
              pdf.text(
                `${this.userData.Instituto.logradouro}, ${this.userData.Instituto.numero}, ${this.userData.Instituto.bairro}, ${this.userData.Instituto.cep} - ${this.userData.Instituto.cidade} - ${this.userData.Instituto.uf} E-mail: ${this.userData.Instituto.emailResponsavel} - Fone:${this.userData.Instituto.telefone}`,
                pdf.internal.pageSize.getWidth() * 0.03,
                pdf.internal.pageSize.getHeight() - 0.15,
              )
            }
          })
          .save()
      },
    },
  }
</script>
<style scoped>
  .fundoRelatorio {
    background-color: white;
    word-break: break-word;
  }

  .titulo {
    background-color: inherit !important;
    justify-content: flex-start;
    word-break: break-all;
    padding-top: 5px;
  }

  .lineBlue {
    width: 288px;
    height: 13px;
    background-color: #4941a6;
  }
  .lineGray {
    width: 563px;
    height: 13px;
    background-color: #b8c2cb;
  }
  .instituto {
    font-size: initial;
    padding-left: 20px;
  }
  .cabecalhoTable {
    margin-bottom: 0px;
    font-size: 12px;
    word-wrap: normal;
    white-space: pre-wrap;
  }

  .bold-footer-row {
    font-weight: bold;
  }

  #logoAgenda {
    margin: 0 10px;
  }

  div.vue-html2pdf div.card-body {
    padding-top: 0 !important;
    padding-bottom: 0 !important;
  }
</style>
